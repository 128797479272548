import { INGREDIENTS_STORE_MODULE_NAME } from '@/app/administration/ingredients/ingredientsStoreModule';
import store from '@/app/app-state';
import { computed, ref } from '@vue/composition-api';
import { PROVIDERS_STORE_MODULE_NAME } from '../../providers/providersStoreModule';
import { DELIVERY_STORE_MODULE_NAME } from '../deliveryStoreModule';

export function useDeliveryCreate() {
  const isOpen = ref(false);
  const delivery = ref({
    ingredients: [],
  });
  const ingredient = ref(null);

  const fetchProviders = () => {
    store.dispatch(`${PROVIDERS_STORE_MODULE_NAME}/fetchProviders`);
  };

  const fetchIngredients = () => {
    store.dispatch(`${INGREDIENTS_STORE_MODULE_NAME}/fetchIngredients`);
  };

  const selectIngredient = (val) => {
    const ingredientStore = store.state[INGREDIENTS_STORE_MODULE_NAME].data.find(
      (i) => i.id === val
    );
    delivery.value.ingredients.push({
      ...ingredientStore,
      one: 0,
      count: 0,
      price: 0,
    });
    ingredient.value = null;
  };

  const deleteIngredient = ({ id }) => {
    delivery.value.ingredients = delivery.value.ingredients.filter((i) => i.id !== id);
  };

  const providers = computed(
    () => store.getters[`${PROVIDERS_STORE_MODULE_NAME}/autocompleteProviders`]
  );

  const ingredients = computed(
    () => store.getters[`${INGREDIENTS_STORE_MODULE_NAME}/autocompleteIngredients`]
  );

  let createDialogController = null;

  const open = () => {
    let resolve;
    let reject;
    const createDialogPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });
    createDialogController = { resolve, reject };
    fetchProviders();
    fetchIngredients();
    isOpen.value = true;
    return createDialogPromise;
  };

  const saveDelivery = () => {
    try {
      store.dispatch(`${DELIVERY_STORE_MODULE_NAME}/createDelivery`, {
        payload: delivery.value,
      });
      isOpen.value = false;
      createDialogController.resolve(true);
    } catch (e) {
      createDialogController.reject(false);
    }
  };

  const changePrice = ({ row }) => {
    row.price = row.one * row.count;
  };

  return {
    isOpen,
    open,
    delivery,
    saveDelivery,
    providers,
    selectIngredient,
    ingredients,
    ingredient,
    deleteIngredient,
    changePrice,
  };
}
