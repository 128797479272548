<template>
  <el-dialog title="Добавление поставщика" :visible.sync="isOpen" width="60%">
    <el-form :model="delivery" label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Поставщик">
            <el-select v-model="delivery.provider_id" placeholder="Поставщик" style="width: 100%">
              <el-option
                v-for="item in providers"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Дата поставки">
            <el-date-picker
              v-model="delivery.date"
              class="input-full"
              type="date"
              :picker-options="{ firstDayOfWeek: 1 }"
              placeholder="Дата поставки"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-select
            v-model="ingredient"
            placeholder="Ингредиент"
            style="width: 100%"
            filterable
            icon="el-icon-search"
            @change="selectIngredient"
          >
            <el-option
              v-for="item in ingredients"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col :span="24">
          <el-table :data="delivery.ingredients">
            <el-table-column prop="title" label="Ингредиент" width="240" />
            <el-table-column prop="count" label="Кол-во">
              <template slot-scope="scope">
                <el-input v-model="scope.row.count" size="small" @input="changePrice(scope)">
                  <template slot="append">
                    {{ scope.row.unit.title }}
                  </template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="Цена за ед.">
              <template slot-scope="scope">
                <el-input v-model="scope.row.one" size="small" @input="changePrice(scope)">
                  <template slot="append"> ₽ </template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="Сумма">
              <template slot-scope="scope">
                <el-input v-model="scope.row.price" size="small">
                  <template slot="append"> ₽ </template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <div class="is-flex is-justify-content-flex-end">
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    @click="deleteIngredient(scope.row)"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isOpen = false">Закрыть</el-button>
      <el-button type="primary" @click="saveDelivery">Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>
import store from '@/app/app-state';
import { onUnmounted } from '@vue/composition-api';
import {
  INGREDIENTS_STORE_MODULE_NAME,
  ingredientsStoreModule,
} from '@/app/administration/ingredients/ingredientsStoreModule';
import { useDeliveryCreate } from './useDeliveryCreate';
import {
  PROVIDERS_STORE_MODULE_NAME,
  providersStoreModule,
} from '../../providers/providersStoreModule';

export default {
  setup() {
    if (!store.hasModule(INGREDIENTS_STORE_MODULE_NAME)) {
      store.registerModule(INGREDIENTS_STORE_MODULE_NAME, ingredientsStoreModule);
    }
    if (!store.hasModule(PROVIDERS_STORE_MODULE_NAME)) {
      store.registerModule(PROVIDERS_STORE_MODULE_NAME, providersStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(INGREDIENTS_STORE_MODULE_NAME)) {
        store.unregisterModule(INGREDIENTS_STORE_MODULE_NAME);
      }
    });

    const {
      isOpen,
      open,
      delivery,
      saveDelivery,
      providers,
      ingredients,
      selectIngredient,
      ingredient,
      deleteIngredient,
      changePrice,
    } = useDeliveryCreate();

    return {
      isOpen,
      open,
      delivery,
      saveDelivery,
      providers,
      ingredients,
      selectIngredient,
      ingredient,
      deleteIngredient,
      changePrice,
    };
  },
};
</script>
