import { httpClient, parseQueryParam } from '@/app/shared/services';

export const DELIVERY_STORE_MODULE_NAME = 'storage-delivery';

export const deliveryStoreModule = {
  namespaced: true,
  state: {
    data: [],
    meta: {},
    dataIngredientCategories: [],
  },
  getters: {
    autocompleteIngredientCategories: (state) =>
      state.dataIngredientCategories.map((c) => ({ value: c.id, label: c.title })),
  },
  mutations: {
    SET_DELIVERY_DATA(state, payload) {
      state.data = payload;
    },
    SET_DELIVERY_META(state, payload) {
      state.meta = payload;
    },
    SET_INGREDIENT_CATEGORIES_DATA(state, payload) {
      state.dataIngredientCategories = payload;
    },
  },
  actions: {
    fetchDelivery({ commit }, queryParams) {
      return new Promise((res, rej) => {
        const params = parseQueryParam(queryParams);
        httpClient
          .get(`/storage/delivery${params}`)
          .then((response) => {
            const { data, meta } = response.data;
            commit('SET_DELIVERY_DATA', data);
            commit('SET_DELIVERY_META', meta);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    exportDelivery(ctx, queryParams) {
      return new Promise((res, rej) => {
        const params = parseQueryParam({ ...queryParams, export: true });
        httpClient
          .get(`/storage/delivery${params}`)
          .then((response) => res(response))
          .catch((error) => rej(error));
      });
    },
    createDelivery({ commit, state }, { payload }) {
      return new Promise((res, rej) => {
        httpClient
          .post('/storage/delivery', payload)
          .then((response) => {
            const { data } = response.data;
            commit('SET_DELIVERY_DATA', data.concat(state.data));
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    deleteDelivery({ commit, state }, { id }) {
      return new Promise((res, rej) => {
        httpClient
          .delete(`/storage/delivery/${id}`)
          .then((response) => {
            commit(
              'SET_DELIVERY_DATA',
              state.data.filter((item) => item.id !== id)
            );
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    fetchIngredientCategories({ commit }) {
      return new Promise((res, rej) => {
        httpClient
          .get('/ingredient/categories', { params: { limit: 0 } })
          .then((response) => {
            const { data } = response.data;
            commit('SET_INGREDIENT_CATEGORIES_DATA', data);
          })
          .catch((error) => rej(error));
      });
    },
  },
};
