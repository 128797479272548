import router from '@/app/app-routes';
import store from '@/app/app-state';
import { computed, ref } from '@vue/composition-api';
import { Notification } from 'element-ui';
import Vue from 'vue';
import { DELIVERY_STORE_MODULE_NAME } from './deliveryStoreModule';

const routeData = Vue.observable({ params: {}, query: {} });

router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
});

export function useDeliveryList() {
  const createDeliveryRef = ref(null);

  const exportExcel = () => {
    store
      .dispatch(`${DELIVERY_STORE_MODULE_NAME}/exportDelivery`, {
        page: routeData.query.page || 1,
        filter: {
          date: routeData.query.date || '',
          title: routeData.query.title || '',
          providerId: routeData.query.providerId || '',
          category: routeData.query.category || '',
        },
      })
      .then((response) => {
        const {
          data: { url },
        } = response.data;
        window.location.href = url;
      });
  };

  const formatterText = (v, row, value) => value || 'Не указано';

  const addDelivery = async () => {
    const createResult = await createDeliveryRef.value.open();
    if (createResult) {
      Notification.success({
        title: 'Успех',
        message: 'Поставка успешно добавлена',
      });
    }
  };

  const fetchDelivery = (params) => {
    store.dispatch(`${DELIVERY_STORE_MODULE_NAME}/fetchDelivery`, params);
  };

  const deleteDelivery = ({ id }) => {
    try {
      store.dispatch(`${DELIVERY_STORE_MODULE_NAME}/deleteDelivery`, { id });
      Notification.success({
        title: 'Успех',
        message: 'Поставка успешно удалена.',
      });
    } catch (err) {
      Notification.error({
        title: 'Ошибка',
        message: 'Ошибка удаления.',
      });
    }
  };

  const meta = computed(() => store.state[DELIVERY_STORE_MODULE_NAME].meta);

  const data = computed(() => store.state[DELIVERY_STORE_MODULE_NAME].data);

  const changePage = (page) => {
    router.replace({ query: { ...routeData.query, page } });
    fetchDelivery({
      page,
      filter: {
        date: routeData.query.date,
        title: routeData.query.title,
        providerId: routeData.query.providerId,
        category: routeData.query.category,
      },
    });
  };

  fetchDelivery({
    page: routeData.query.page || 1,
    filter: {
      date: routeData.query.date,
      title: routeData.query.title,
      providerId: routeData.query.providerId,
      category: routeData.query.category,
    },
  });

  const applyFilter = (value) => {
    router.replace({ query: value });
    fetchDelivery({ filter: value });
  };

  return {
    meta,
    data,
    changePage,
    deleteDelivery,
    formatterText,
    applyFilter,
    exportExcel,
    addDelivery,
    createDeliveryRef,
  };
}
