<template>
  <div class="delivery-filter">
    <div class="is-flex is-justify-content-space-between mb-4">
      <el-button size="small" icon="el-icon-set-up" @click="openFilter"> Фильтр </el-button>
      <div class="buttons">
        <el-button icon="el-icon-download" type="primary" size="small" @click="exportExcel">
          Экспорт Excel
        </el-button>
        <el-button icon="el-icon-plus" type="primary" size="small" @click="addDelivery">
          Добавить поставку
        </el-button>
      </div>
    </div>
    <div v-if="isOpenFilter" class="is-flex is-justify-content-flex-start mt-4">
      <el-input
        v-model="searchTitle"
        placeholder="Название"
        size="small"
        style="width: 20%"
        class="mr-4"
      />

      <el-date-picker
        v-model="searchDate"
        type="daterange"
        align="right"
        start-placeholder="От"
        end-placeholder="До"
        size="small"
        :picker-options="{ firstDayOfWeek: 1 }"
        class="mr-4"
        format="dd MMMM yyyy"
        value-format="yyyy-MM-dd"
      />

      <el-select
        v-model="searchCategories"
        multiple
        collapse-tags
        placeholder="Категории"
        size="small"
        class="mr-4"
      >
        <el-option
          v-for="item in categories"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <el-select
        v-model="searchProviders"
        multiple
        collapse-tags
        placeholder="Поставщики"
        size="small"
      >
        <el-option
          v-for="item in providers"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import store from '@/app/app-state';
import { onUnmounted } from '@vue/composition-api';
import { useDeliveryFilter } from './useDeliveryFilter';
import {
  PROVIDERS_STORE_MODULE_NAME,
  providersStoreModule,
} from '../../providers/providersStoreModule';

export default {
  setup() {
    if (!store.hasModule(PROVIDERS_STORE_MODULE_NAME)) {
      store.registerModule(PROVIDERS_STORE_MODULE_NAME, providersStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(PROVIDERS_STORE_MODULE_NAME)) {
        store.unregisterModule(PROVIDERS_STORE_MODULE_NAME);
      }
    });

    const {
      isOpenFilter,
      providers,
      categories,
      openFilter,
      searchDate,
      searchProviders,
      searchCategories,
      searchTitle,
      exportExcel,
      addDelivery,
    } = useDeliveryFilter();
    return {
      isOpenFilter,
      providers,
      categories,
      openFilter,
      searchDate,
      searchProviders,
      searchCategories,
      searchTitle,
      exportExcel,
      addDelivery,
    };
  },
};
</script>
