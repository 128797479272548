import store from '@/app/app-state';
import { computed, getCurrentInstance, ref, watch } from '@vue/composition-api';
import { PROVIDERS_STORE_MODULE_NAME } from '../../providers/providersStoreModule';
import { DELIVERY_STORE_MODULE_NAME } from '../deliveryStoreModule';

export function useDeliveryFilter() {
  const isOpenFilter = ref(false);
  const searchDate = ref(null);
  const searchProviders = ref([]);
  const searchCategories = ref([]);
  const searchTitle = ref(null);

  const providers = computed(
    () => store.getters[`${PROVIDERS_STORE_MODULE_NAME}/autocompleteProviders`]
  );
  const categories = computed(
    () => store.getters[`${DELIVERY_STORE_MODULE_NAME}/autocompleteIngredientCategories`]
  );

  const { emit } = getCurrentInstance();

  const exportExcel = () => {
    emit('export-excel');
  };

  const addDelivery = () => {
    emit('add-delivery');
  };

  watch([searchTitle, searchDate, searchProviders, searchCategories], (value) => {
    emit('filter-value', {
      title: value[0] || undefined,
      date: value[1] ? value[1].join(',') : undefined,
      providerId: value[2].length ? value[2].join(',') : undefined,
      category: value[3].length ? value[3].join(',') : undefined,
    });
  });

  const fetchProviders = () => {
    store.dispatch(`${PROVIDERS_STORE_MODULE_NAME}/fetchProviders`);
  };

  const fetchIngredientCategories = () => {
    store.dispatch(`${DELIVERY_STORE_MODULE_NAME}/fetchIngredientCategories`);
  };

  const openFilter = () => {
    fetchProviders();
    fetchIngredientCategories();
    isOpenFilter.value = !isOpenFilter.value;
  };

  return {
    isOpenFilter,
    providers,
    categories,
    searchDate,
    searchProviders,
    searchCategories,
    searchTitle,
    openFilter,
    exportExcel,
    addDelivery,
  };
}
