<template>
  <div class="storage-delivery">
    <delivery-filter
      @export-excel="exportExcel"
      @filter-value="applyFilter"
      @add-delivery="addDelivery"
    />
    <el-table :data="data" style="width: 100%" lazy>
      <el-table-column prop="id" label="#" width="80" />
      <el-table-column prop="ingredient.title" label="Наименование" width="180" />
      <el-table-column prop="ingredient.category.title" label="Категория" width="180" />
      <el-table-column prop="ingredient.unit.title" label="Ед. изм." width="100" />
      <el-table-column prop="count" label="Кол-во." width="100" />
      <el-table-column prop="cost_price" label="Себестоим." />
      <el-table-column prop="provider.name" label="Поставщик" :formatter="formatterText" />
      <el-table-column prop="date_format" label="Дата" />
      <el-table-column width="70">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="deleteDelivery(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination is-flex is-justify-content-center mt-5">
      <el-pagination
        class=""
        background
        layout="prev, pager, next"
        :current-page="meta.current_page"
        :page-size="meta.per_page"
        :page-count="meta.last_page"
        @current-change="changePage"
      />
    </div>
    <delivery-create ref="createDeliveryRef" />
  </div>
</template>
<script>
import store from '@/app/app-state';
import { onUnmounted } from '@vue/composition-api';
import { useDeliveryList } from './useDeliveryList';
import { deliveryStoreModule, DELIVERY_STORE_MODULE_NAME } from './deliveryStoreModule';
import DeliveryFilter from './delivery-filter/delivery-filter.vue';
import DeliveryCreate from './delivery-create/delivery-create.vue';

export default {
  name: 'StorageDelivery',
  components: { DeliveryFilter, DeliveryCreate },
  setup() {
    if (!store.hasModule(DELIVERY_STORE_MODULE_NAME)) {
      store.registerModule(DELIVERY_STORE_MODULE_NAME, deliveryStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(DELIVERY_STORE_MODULE_NAME)) {
        store.unregisterModule(DELIVERY_STORE_MODULE_NAME);
      }
    });

    const {
      meta,
      data,
      changePage,
      deleteProvider,
      deleteDelivery,
      formatterText,
      exportExcel,
      applyFilter,
      addDelivery,
      createDeliveryRef,
    } = useDeliveryList();

    return {
      meta,
      data,
      changePage,
      deleteProvider,
      deleteDelivery,
      formatterText,
      exportExcel,
      applyFilter,
      addDelivery,
      createDeliveryRef,
    };
  },
};
</script>
