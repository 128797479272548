import { httpClient } from '../../shared/services';

export const PROVIDERS_STORE_MODULE_NAME = 'storage-providers';

export const providersStoreModule = {
  namespaced: true,
  state: {
    data: [],
    meta: {},
  },
  getters: {
    autocompleteProviders: (state) => state.data.map((p) => ({ value: p.id, label: p.name })),
  },
  mutations: {
    SET_PROVIDERS_DATA(state, payload) {
      state.data = payload;
    },
    SET_PROVIDERS_META(state, payload) {
      state.meta = payload;
    },
  },
  actions: {
    fetchProviders({ commit }, params) {
      return new Promise((res, rej) => {
        httpClient
          .get('/storage/providers', { params })
          .then((response) => {
            const { data, meta } = response.data;
            commit('SET_PROVIDERS_DATA', data);
            commit('SET_PROVIDERS_META', meta);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    createProvider({ commit, state }, payload) {
      return new Promise((res, rej) => {
        httpClient
          .post('/storage/providers', payload)
          .then((response) => {
            const { data } = response.data;
            const stateData = state.data;
            stateData.push(data);
            commit('SET_PROVIDERS_DATA', stateData);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    updateProviders(_, { id, payload }) {
      return new Promise((res, rej) => {
        httpClient
          .put(`/storage/providers/${id}`, payload)
          .then((response) => res(response))
          .catch((error) => rej(error));
      });
    },
    deleteProvider({ commit, state }, { id }) {
      return new Promise((res, rej) => {
        httpClient
          .delete(`/storage/providers/${id}`)
          .then((response) => {
            commit(
              'SET_PROVIDERS_DATA',
              state.data.filter((item) => item.id !== id)
            );
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
  },
};
